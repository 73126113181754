import { Helmet } from 'react-helmet-async';

export default function ProductsPage() {
  return (
    <>
      <Helmet>
        <title> Dashboard: Products </title>
      </Helmet>
    </>
  );
}

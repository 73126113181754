import { Avatar, Box, Button, Card, CardActionArea, CardContent, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { fToNow } from '../../../utils/formatTime';
import StandardChildStatus from '../../accreditation/helpers/StandardChildStatus';

export default function ChildStandardNotification({ notification }) {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNotificationClick = () => {
    navigate(`/accreditation/${notification.inner_notification.accreditation_id}`);
  };

  if (!notification || !notification.inner_notification || !notification.inner_notification.id) {
    return null;
  }

  return (
    <Card
      sx={{
        mt: 2,
        mb: 2,
        background: 'transparent',
        boxShadow: 'none',
        border: 'solid 1px gray',
        color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
      }}
    >
      <CardActionArea onClick={handleNotificationClick}>
        <CardContent sx={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
          <Box>
            <Avatar
              variant="square"
              sx={{
                backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
                color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
              }}
            >
              {notification.inner_notification.standard_id}.{notification.inner_notification.standard_child_table_id}
            </Avatar>
          </Box>
          <Box sx={{ ml: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '8px' }}>
              <Button
                sx={{
                  backgroundColor: 'transparent',
                  color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                }}
              >
                Standard {notification.inner_notification.standard_id}.
                {notification.inner_notification.standard_child_table_id}
              </Button>
              <Box sx={{ ml: 1 }}>
                <StandardChildStatus item={notification.inner_notification.status} />

                {/* {statusChip(notification.inner_notification.status || '')} */}
              </Box>
            </Box>
            <Box sx={{ ml: 2 }} className="flex  align-center">
              <Typography sx={{ mr: 1, fontSize: '13px' }}>
                Standard {notification.inner_notification.standard_id}.
                {notification.inner_notification.standard_child_table_id} has been marked{' '}
                {notification.inner_notification.status}
              </Typography>
              <Typography sx={{ mr: 1, fontSize: '13px' }}>{fToNow(notification.timestamp)}</Typography>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

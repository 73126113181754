import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_THEME } from '../store/types';

const THEME_KEY = 'theme'; // Key to store the theme in localStorage

export function useGetTheme() {
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.themeStatus); // Assuming you have a theme state in your Redux store
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get the theme from localStorage
    const storedTheme = localStorage.getItem(THEME_KEY);

    // If the theme is not found in localStorage, set it to 'dark' and save it in localStorage
    if (!storedTheme) {
      localStorage.setItem(THEME_KEY, 'dark');
      dispatch({ type: SET_THEME, payload: 'dark' }); // Assuming you have a Redux action to set the theme
    } else {
      // If the theme exists in localStorage, update the theme state in Redux
      dispatch({ type: SET_THEME, payload: storedTheme });
    }

    setLoading(false); // Mark the theme retrieval process as completed
  }, [dispatch]);

  return { localStorageTheme: theme, loading };
}

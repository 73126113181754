import { Box, IconButton, Typography } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useDispatch } from 'react-redux';
import { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { deleteFile } from '../../../store/actions/files';
import { AccreditationContext } from '..';

export default function DeleteDocument({ document, company }) {
  const dispatch = useDispatch();
  const { accreditationData } = useContext(AccreditationContext);

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const removeFile = () => {
    dispatch(
      deleteFile(company, document.name, document.accreditation_id, document.standard_child_id, document.standard_id)
    );
    handleClose();
  };

  if (document.document_status !== 'Approved') {
    return (
      <Box>
        <IconButton onClick={handleClickOpen}>
          <DeleteForeverIcon />
        </IconButton>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title" sx={{ backgroundColor: '#b45454', color: 'white' }}>
            {'Are you sure you want to delete this file?'}
          </DialogTitle>
          <DialogContent sx={{ mt: 2 }}>
            <DialogContentText id="alert-dialog-description">
              Deleting this file will permanently remove it from our servers! Do you wish to proceed deleting this file?
            </DialogContentText>
            <Typography sx={{ mt: 2 }}>Deleting File - {document.name}</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button onClick={removeFile} autoFocus>
              Delete File
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    );
  }
  return <></>;
}

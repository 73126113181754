import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Chip,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import StandardChildStatus from '../../accreditation/helpers/StandardChildStatus';
import { formatAccreditationDate } from '../../../helpers/formatAccreditationDate';

export default function AccreditationAccordion({ userData }) {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();

  const navigate = useNavigate();
  return (
    <Box>
      <Accordion
        defaultExpanded={'true'}
        sx={{
          backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
          color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Typography variant="h6">
            {(userData && userData.accreditation_type_header_title) || 'Avaliable Accreditations'}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            {userData &&
              userData.accreditation.length >= 1 &&
              userData.accreditation.map((item) => (
                <>
                  <Grid item md={4} sm={12} xs={12}>
                    <Card
                      sx={{
                        backgroundColor:
                          themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
                        color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                        border: 'solid gray 1px',
                      }}
                    >
                      <CardActionArea onClick={(e) => navigate(`/accreditation/${item.accreditation_id}`)}>
                        <CardMedia
                          sx={{ height: 180 }}
                          image="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/black-certification.svg"
                          title="certification"
                        />
                        <CardContent>
                          <Typography variant="h5">
                            {userData.company_name.concat(' ', item.accreditation_type_value) || ''}
                          </Typography>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            {formatAccreditationDate(item.accreditation_date_created)} -{' '}
                            {formatAccreditationDate(item.accreditation_end_date)}
                            <StandardChildStatus item={item.accreditation_status} />
                          </Box>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </Grid>
                </>
              ))}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

import { Alert, Avatar, Box, Button, Card, CardActionArea, CardContent, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { statusChip } from '../../../helpers/statusChip';
import { formatString } from '../../../helpers/formatString';
import DocumentImg from '../../accreditation/helpers/DocumentImg';
import { fToNow } from '../../../utils/formatTime';
import StandardChildStatus from '../../accreditation/helpers/StandardChildStatus';

export default function StandardNotification({ notification }) {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  const navigate = useNavigate();

  const handleNotificationClick = () => {
    navigate(`/accreditation/${notification.inner_notification.accreditation_id}`);
  };

  if (!notification || !notification.inner_notification || !notification.inner_notification.id) {
    return null; // If there is no inner_notification, return null (show nothing)
  }

  return (
    <>
      <Card
        sx={{
          mt: 2,
          mb: 2,
          background: 'transparent',
          boxShadow: 'none',
          border: 'solid 1px gray',
          color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
        }}
      >
        <CardActionArea onClick={handleNotificationClick}>
          <CardContent sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              variant="square"
              sx={{
                backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
                color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
              }}
            >
              {notification.inner_notification.standard_id}
            </Avatar>
            <Box sx={{ ml: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  sx={{
                    backgroundColor: 'transparent',
                    color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                  }}
                >
                  Standard {notification.inner_notification.standard_id}
                </Button>
                <Box sx={{ ml: 1 }}>
                  <StandardChildStatus item={notification.inner_notification.status} />

                  {/* {statusChip(notification.inner_notification.status || '')} */}
                </Box>
                <Box sx={{ ml: 1 }}>{statusChip(notification.inner_notification.document_status || '')}</Box>
              </Box>
              <Box sx={{ ml: 1 }} className="flex  align-center">
                <Typography sx={{ mr: 1, fontSize: '13px' }}>
                  Standard {notification.inner_notification.standard_id || 0} has been marked{' '}
                  {notification.inner_notification.status}
                </Typography>
                <Typography sx={{ mr: 1, fontSize: '13px' }}>{fToNow(notification.timestamp)}</Typography>
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
}

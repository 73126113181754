import { combineReducers } from 'redux';
import userReducers from './userReducers';
import standardsReducers from './standardsReducers';
import filesReducers from './filesReducers';
import themeReducers from './themeReducers';
import accreditationReducers from './accreditationReducers';
import notificationReducers from './notificationReducers';

export default combineReducers({
  user: userReducers,
  standards: standardsReducers,
  files: filesReducers,
  theme: themeReducers,
  accreditation: accreditationReducers,
  notification: notificationReducers,
});

import { useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
// import { API_URL_ADMIN_USER } from "../constants/API";

export function useGetUser() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const [userAdditionalInfo, setUserAdditionalInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [additionalInfoLoading, setAdditionalInfoLoading] = useState(true);

  useEffect(() => {
    async function checkAuth() {
      try {
        const data = await Auth.currentAuthenticatedUser();
        if (data) {
          setLoading(false);
          setIsLoggedIn(true);
          setUser(data);
        } else {
          setLoading(false);
          setIsLoggedIn(false);
          setUser(null);
        }
      } catch (err) {
        setLoading(false);
        setIsLoggedIn(false);
        setUser(null);
      }
    }

    checkAuth();
  }, []);

  return {
    isLoggedIn,
    user,
    loading,
    userAdditionalInfo,
    additionalInfoLoading,
  };
}

import { Avatar, Box, ListItemButton, ListItemText, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';
import DocumentImg from '../../../pages/accreditation/helpers/DocumentImg';
import { fToNow } from '../../../utils/formatTime';
import { formatString } from '../../../helpers/formatString';
import { statusChip } from '../../../helpers/statusChip';
import StandardChildStatus from '../../../pages/accreditation/helpers/StandardChildStatus';

export default function StandardNavNotification({ notification, handleOpenOutside }) {
  const navigate = useNavigate();

  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  const notificationNavigate = () => {
    handleOpenOutside(false);
    navigate(`/accreditation/${notification.inner_notification.accreditation_id}`);
  };

  return (
    <ListItemButton
      onClick={notificationNavigate}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <Box className="flex justify-center align-center">
        <Avatar
          variant="square"
          sx={{
            backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
          }}
        >
          {notification.inner_notification.standard_id}
        </Avatar>
        <Box sx={{ ml: 1 }}>
          <ListItemText
            primary={
              <Box className="flex justify-center align-center">
                <Typography>Standard {notification.inner_notification.standard_id}</Typography>
                <Box sx={{ ml: 1 }}>
                  <StandardChildStatus item={notification.inner_notification.status} />
                </Box>
              </Box>
            }
            secondary={
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.disabled',
                }}
              >
                <AccessTimeIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
                {fToNow(notification.timestamp)}
              </Typography>
            }
          />
        </Box>
      </Box>
    </ListItemButton>
  );
}

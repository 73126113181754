import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

// @mui
import { Box, List, ListItemText, useTheme } from '@mui/material';
//
import { StyledNavItem, StyledNavItemIcon } from './styles';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();

  return (
    <Box {...other}>
      <List
        disablePadding
        sx={{ p: 1, color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
      >
        {data.map((item) => (
          <NavItem key={item.title} item={item} />
        ))}
      </List>
    </Box>
  );
}

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

function NavItem({ item }) {
  const { title, path, icon, info } = item;
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  return (
    <StyledNavItem
      component={RouterLink}
      to={path}
      sx={{
        '&.active': {
          color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
          bgcolor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
          fontWeight: 'fontWeightBold',
        },
      }}
    >
      <StyledNavItemIcon
        style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
      >
        {icon && icon}
      </StyledNavItemIcon>

      <ListItemText
        style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
        disableTypography
        primary={title}
      />

      {info && info}
    </StyledNavItem>
  );
}

import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Container,
  IconButton,
  SwipeableDrawer,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';

import useResponsive from '../../../hooks/useResponsive';
import Label from '../../../components/label/Label';
import { formatAccreditationDate } from '../../../helpers/formatAccreditationDate';

export default function ParentAccreditationDetails({ userData, accreditationData }) {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  const isDesktop = useResponsive('up', 'md');
  const anchor = 'right';
  const [state, setState] = useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const drawerSize = isDesktop ? '500px' : '350px';

  return (
    <>
      <CardActionArea onClick={toggleDrawer('right', true)}>
        <CardContent style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ width: '100%' }}>
            <Box>
              {accreditationData.accreditation_status === 'Not Yet Started' ? (
                <>
                  <Label color="error">Not Yet Started</Label>
                </>
              ) : accreditationData.accreditation_status === 'Under Review' ? (
                <>
                  <Label color="warning">Under Review</Label>
                </>
              ) : (
                <>
                  <Label color="success">Completed</Label>
                </>
              )}
            </Box>
            <Typography variant="h4">
              {userData.company_name} {userData.accreditation_type_detail}
            </Typography>
            {/* <Typography>
              {userData.accreditation_type_header_sub_title || "ACMA's Organizational Accreditation Portal"}
            </Typography> */}

            <Typography sx={{ fontSize: '14px' }}>
              Accreditation Period : {formatAccreditationDate(accreditationData.accreditation_date_created)} -{' '}
              {formatAccreditationDate(accreditationData.accreditation_end_date)}
            </Typography>
          </Box>
          <Box className="flex justify-end" sx={{ width: '100%' }}>
            <img src={userData && userData.company_logo} alt="company_logo" />
          </Box>
        </CardContent>
      </CardActionArea>

      <SwipeableDrawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false, {}, 0)}>
        <Container
          sx={{
            overflowY: 'scroll',
            backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
            height: '100%',
            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
          }}
        >
          <Box
            sx={{
              maxWidth: drawerSize,
              width: drawerSize,
              mt: 1,
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <IconButton aria-label="delete" size="large" onClick={toggleDrawer('right', false)}>
              <CloseIcon
                style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
              />
            </IconButton>
          </Box>

          <Box sx={{ mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <img src={userData.company_logo} alt={userData.company_name} style={{ width: '100%' }} />
              </Box>
              <Box>
                {accreditationData.accreditation_status === 'Not Yet Started' ? (
                  <>
                    <Label color="error">Not Yet Started</Label>
                  </>
                ) : accreditationData.accreditation_status === 'Under Review' ? (
                  <>
                    <Label color="warning">Under Review</Label>
                  </>
                ) : (
                  <>
                    <Label color="success">Completed</Label>
                  </>
                )}
              </Box>
            </Box>
            <Box sx={{ mt: 2, display: 'flex' }}>
              <Box sx={{ mt: 4 }}>
                <Typography variant="h6">
                  {userData.company_name} {userData.accreditation_type_detail}
                </Typography>
                <Typography sx={{ mt: 0.5 }}>
                  Accreditation Period : {formatAccreditationDate(accreditationData.accreditation_date_created)} -{' '}
                  {formatAccreditationDate(accreditationData.accreditation_end_date)}
                </Typography>
              </Box>
            </Box>

            <Box>
              {accreditationData && accreditationData.accreditation_date_completed ? (
                <>
                  <Typography sx={{ mt: 0.5 }}>
                    Completion Date : {formatAccreditationDate(accreditationData.accreditation_date_completed)}
                  </Typography>
                  <Typography sx={{ mt: 0.5 }}>Serial # : {accreditationData.accreditation_serial_number}</Typography>
                </>
              ) : (
                <></>
              )}
            </Box>
          </Box>

          {accreditationData.accreditation_status === 'Completed' ? (
            <>
              <Box sx={{ mt: 4, display: 'flex', justifyContent: 'center' }}>
                <img src={accreditationData.badge_url} alt="badge" width={'70%'} />
              </Box>
            </>
          ) : (
            <>
              <Card
                sx={{
                  mt: 5,
                  maxWidth: drawerSize,
                  width: drawerSize,
                  backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
                  color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                }}
              >
                <CardContent sx={{ width: '100%' }}>
                  <Typography>
                    After all standards are completed and the status is set to completed, your assets will be available
                    here for easy download.
                  </Typography>
                </CardContent>
              </Card>
            </>
          )}
        </Container>
      </SwipeableDrawer>
    </>
  );
}

import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetUser } from '../../hooks/useGetUser';

export function withAuth(WrappedComponent) {
  return (props) => {
    const navigate = useNavigate();
    const { isLoggedIn, loading } = useGetUser();

    useEffect(() => {
      if (!loading && !isLoggedIn) {
        navigate('/login');
      }
    }, [isLoggedIn, loading]);

    return <WrappedComponent {...props} />;
  };
}

import { Helmet } from 'react-helmet-async';
// @mui

import { styled } from '@mui/material/styles';
import { Link, Container, Typography, Divider, Stack, Button, Box, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { withNoAuth } from '../components/HOC/withNoAuth';

// hooks
import useResponsive from '../hooks/useResponsive';
// components

// sections
import { LoginForm } from '../sections/auth/login';

// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex',
  },
}));

const StyledSection = styled('div')(({ theme }) => ({
  width: '100%',
  maxWidth: 480,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

function LoginPage() {
  const mdUp = useResponsive('up', 'md');
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title> Login </title>
      </Helmet>

      <StyledRoot>
        {mdUp && (
          <StyledSection
            style={{
              backgroundColor: themeStatus === 'dark' ? '#021f28' : 'transparent',
              color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
              boxShadow: 'none',
            }}
          >
            <Typography variant="h3" sx={{ px: 5, mt: 3, mb: 5 }}>
              Hi, Welcome Back
            </Typography>
            <img src="/assets/illustrations/illustration_login.png" alt="login" />
          </StyledSection>
        )}

        <Container maxWidth="sm">
          <StyledContent>
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}

export default withNoAuth(LoginPage);

import { Accordion, Box, useTheme } from '@mui/material';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import NotificationsIcon from '@mui/icons-material/Notifications';

export default function Rejected({ message }) {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();

  return (
    <>
      {message && message.length >= 1 ? (
        <Box>
          <Accordion
            sx={{
              backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
              color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <NotificationsIcon style={{ color: '#f4b521' }} />
                <Typography sx={{ ml: 1, fontWeight: 700 }}>New Message</Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{message}</Typography>
            </AccordionDetails>
          </Accordion>
        </Box>
      ) : (
        <></>
      )}
    </>
  );
}

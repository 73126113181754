import { Auth } from 'aws-amplify';
import axios from 'axios';
import { GET_NOTIFICATIONS } from '../types';
import { NOTIFICATIONS_STANDARD_API_URL } from '../API';

export const getNotifications = (companyId, type, accreditationType) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;

  try {
    const res = await axios.get(NOTIFICATIONS_STANDARD_API_URL, {
      headers: {
        Authorization: `Bearer ${token}`,
        'content-type': 'application/json',
      },
      params: {
        companyId,
        notification_type: type,
        accreditationType,
      },
    });
    if (res && res.data) {
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: res.data,
      });
    }
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log('error', err.response.data);
      console.log('error', err.response.status);
    } else if (err.request) {
      console.log('error', err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};

export const markallNotificationsRead = () => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
};

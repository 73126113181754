import { Link } from 'react-router-dom';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Box, Grid, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

export default function Footer() {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: themeStatus === 'dark' ? '#0a0d12' : '#d6d6d6',
        color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
      }}
    >
      <footer className="footer">
        <div className="footer-container">
          <div className="footer-row">
            <div
              className="footer-col"
              style={{
                color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
              }}
            >
              <h4>ACMA's Training Academy Accreditation Portal</h4>
              <ul>
                <li>
                  <Link
                    to="/home"
                    style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
                  >
                    Home
                  </Link>
                </li>
                <li>
                  <Link
                    to="/login"
                    style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
                  >
                    Login
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>Explore Certifications</h4>
              <ul>
                <li>
                  <Link
                    style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
                    to="https://medicalaffairsspecialist.org/certifications/bcmas"
                  >
                    Board Certified Medical Affairs Speclialist
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
                    to="https://www.priorauthtraining.org/"
                  >
                    Prior Authorization Certified Specialist
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
                    to="https://medicalaffairsspecialist.org/certifications/bcbbs"
                  >
                    Board Certified Biologics and Biosimilars Program
                  </Link>
                </li>
                <li>
                  <Link
                    style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
                    to="https://www.pharmasalestraining.org/"
                  >
                    Pharmaceutical Representative Sales Program
                  </Link>
                </li>
              </ul>
            </div>
            <div className="footer-col">
              <h4>get help</h4>
              <ul>
                <li>
                  <Link
                    style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
                    to="https://medicalaffairsspecialist.org/about/contact-us"
                  >
                    Contact Us
                  </Link>
                </li>

                <li>
                  <a
                    style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
                    href="https://medicalaffairsspecialist.org/about"
                  >
                    About ACMA
                  </a>
                </li>
                <li>
                  <Link
                    style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
                    to="https://medicalaffairsspecialist.org/policy/terms-and-conditions"
                  >
                    Terms & Conditions
                  </Link>
                </li>
              </ul>
            </div>

            <div className="footer-col">
              <h4>follow us</h4>
              <div>
                <h6
                  style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
                >
                  Accreditation Council For Medical Affairs (ACMA)
                </h6>
                <div>
                  <a href="https://www.facebook.com/medicalaffairsspecialist/">
                    <FacebookRoundedIcon className="footer-social-icons" />
                  </a>
                  <a href="https://twitter.com/The_ACMA?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor">
                    <TwitterIcon className="footer-social-icons ml-2" />
                  </a>
                  <a href="https://www.instagram.com/acmapharma/">
                    <InstagramIcon className="footer-social-icons ml-2" />
                  </a>
                  <a href="https://www.linkedin.com/company/accreditation-council-for-medical-affairs-acma-/mycompany/verification/">
                    <LinkedInIcon className="footer-social-icons ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item md={12} className="flex justify-center align-center">
              <Typography
                varaint="h7"
                style={{
                  color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                  fontSize: '16px',
                }}
                className="flex mt-4 text-center"
              >
                Copyright © 2023 Accreditation Council for Medical Affairs. All Rights Reserved.
              </Typography>
            </Grid>
          </Grid>
        </div>
      </footer>
    </Box>
  );
}

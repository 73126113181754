import { Box, CircularProgress, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

export default function CustomLoader() {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  return (
    <Box
      style={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.light,
        color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
      }}
    >
      <CircularProgress />
    </Box>
  );
}

import { Auth } from 'aws-amplify';
import axios from 'axios';
import { GET_STANDARDS } from '../types';
import { MAIN_STANDARD_API_URL } from '../API';

export const getStandards = (accreditationId) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;

  try {
    const res = await axios.get(MAIN_STANDARD_API_URL, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        accreditationId,
        email,
      },
    });
    if (res) {
      dispatch({
        type: GET_STANDARDS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: GET_STANDARDS,
      payload: {},
    });
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};

import axios from 'axios';
import { MAIN_USER_CHECK_API_URL } from '../store/API';

// Replace with your actual API endpoint URL

export const checkAccessToLogin = async (email) => {
  try {
    const response = await axios.get(`${MAIN_USER_CHECK_API_URL}`, {
      headers: {
        'content-type': 'application/json',
      },
      params: {
        email,
      },
    });

    return response.data;
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log('error', err.response.data);
      console.log('error', err.response.status);
    } else if (err.request) {
      console.log('error', err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
    return err;
  }
};

import { useContext } from 'react';
import { Box, Button, Card, CardContent, Typography } from '@mui/material';
import ParentAccreditationDetails from './ParentAccreditationDetails';

import { AccreditationContext } from '..';
import { formatAccreditationDate } from '../../../helpers/formatAccreditationDate';

export default function ExpiredAccreditation() {
  const { userData, accreditationData } = useContext(AccreditationContext);

  return (
    <Box>
      <Card>
        <CardContent>
          <ParentAccreditationDetails userData={userData} accreditationData={accreditationData} />
        </CardContent>
      </Card>

      <Card sx={{ mt: 5 }}>
        <CardContent>
          <Typography>
            Your access to the ACMA's portal has expired on{' '}
            {formatAccreditationDate(accreditationData.accreditation_end_date)}. For an extension please contact us
            below.
          </Typography>
          <a href="https://medicalaffairsspecialist.org/about/contact-us" target="_blank" rel="noreferrer">
            Contact ACMA
          </a>
        </CardContent>
      </Card>
    </Box>
  );
}

import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// @mui
import { useSelector } from 'react-redux';
import { Container, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import useResponsive from '../../hooks/useResponsive';
import Footer from '../../components/ui/Footer';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 0,
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 0,
  },
}));

// ----------------------------------------------------------------------

export default function UnAuthLayout() {
  const [open, setOpen] = useState(false);
  const isDesktop = useResponsive('up', 'lg');
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();

  return (
    <StyledRoot
      style={{
        backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
        color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
      }}
    >
      <Header onOpenNav={() => setOpen(true)} />
      {!isDesktop ? <Nav openNav={open} onCloseNav={() => setOpen(false)} /> : ''}

      <Main>
        <Outlet />
        <Footer />
      </Main>
    </StyledRoot>
  );
}

export default function DocumentImg({ name }) {
  const isPDF = name.toLowerCase().includes('.pdf');
  const isDOCX = name.toLowerCase().includes('.docx');
  const isSpreadsheet = name.toLowerCase().includes('.xls');
  const isCsv = name.toLowerCase().includes('.csv');

  if (isPDF) {
    return (
      <div>
        <img
          src={'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/pdf.svg'}
          alt="PDF"
          width={'100%'}
        />
      </div>
    );
  }
  if (isDOCX) {
    return (
      <div>
        <img
          src={'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/doc.svg'}
          alt="DOCX"
          width={'100%'}
        />
      </div>
    );
  }

  if (isSpreadsheet) {
    return (
      <div>
        <img
          src={'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/spreadsheet.svg'}
          alt="Spreadsheet"
          width={'100%'}
        />
      </div>
    );
  }

  if (isCsv) {
    return (
      <div>
        <img
          src={'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/spreadsheet.svg'}
          alt="Spreadsheet"
          width={'100%'}
        />
      </div>
    );
  }
}

/* eslint-disable */

const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: 'us-east-1:10cba5c6-4009-4588-afdc-d9553b01c0f2',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_66TD5zk4Z',
  aws_user_pools_web_client_id: '1qvlqn89j0qq88r67t1c18tua6',
  oauth: {},
  aws_cognito_username_attributes: ['EMAIL'],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ['EMAIL', 'NAME'],
  aws_cognito_mfa_configuration: 'OFF',
  aws_cognito_mfa_types: ['SMS'],
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: [],
  },
  aws_cognito_verification_mechanisms: ['EMAIL'],
};
export default awsmobile;

export const MAIN_API_URL = 'https://bj1m4awt1c.execute-api.us-east-1.amazonaws.com/default';
export const FILE_UPLOAD_URL = 'https://mea30kjhxj.execute-api.us-east-1.amazonaws.com/default';
export const MAIN_USER_API_URL = MAIN_API_URL.concat('/user');
export const MAIN_USER_CHECK_API_URL = MAIN_API_URL.concat('/check-access-to-login');

export const MAIN_STANDARD_API_URL = MAIN_API_URL.concat('/standards');
export const USERS_STANDARD_API_URL = MAIN_API_URL.concat('/users');
export const NOTIFICATIONS_STANDARD_API_URL = MAIN_API_URL.concat('/notifications');
export const CHILD_STANDARD_API_URL = MAIN_API_URL.concat('/child-standard');
export const ADMIN_EMAIL = MAIN_API_URL.concat('/email');
export const FILE_SUCCESSFULLY_UPLOADED_EMAIL = MAIN_API_URL.concat('/email/file-upload');

export const ACCREDITATION_STANDARD_API_URL = MAIN_API_URL.concat('/accreditation');
export const MAIN_FILES_API_URL = FILE_UPLOAD_URL.concat('/files');
export const GET_FILES_API_URL = MAIN_API_URL.concat('/files');

import { Typography, Box, Container, Grid } from '@mui/material';
import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined';
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined';
import { useSelector } from 'react-redux';

export default function Mission() {
  const themeStatus = useSelector((state) => state.theme.themeStatus);

  return (
    <Container>
      <Box>
        <Typography variant="h1">
          What's <span className="animate-charcter">most</span>
        </Typography>
        <Typography variant="h1">
          <span className="animate-charcter">important</span> to us.
        </Typography>
      </Box>

      <Grid container spacing={3} sx={{ mt: 5 }}>
        <Grid item md={6}>
          <Box className="flex align-center">
            <Box>
              <Box className="flex">
                <LooksOneOutlinedIcon
                  sx={{
                    fontSize: '85px',
                    color: themeStatus === 'dark' ? 'white' : '#021f28',
                  }}
                />
                <Box>
                  <Typography sx={{ ml: 1 }} variant="h3">
                    Our Mission
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '18px',
                      ml: 1,
                      mt: 1,
                      color: themeStatus === 'dark' ? '#919eab' : 'black',
                    }}
                  >
                    is to empower medical affairs and pharmaceutical organizations, recognizing their value. We achieve
                    this by accrediting them to the highest levels and celebrating their dedication, motivating their
                    teams. Our mission is to reach a healthcare environment where every effort is valued, recognized,
                    and improves patients' lives worldwide.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item md={6}>
          <Box className="flex align-center">
            <Box>
              <Box className="flex">
                <LooksTwoOutlinedIcon
                  sx={{
                    fontSize: '85px',
                    color: themeStatus === 'dark' ? 'white' : '#021f28',
                  }}
                />
                <Box>
                  <Typography sx={{ ml: 1 }} variant="h3">
                    Our Vision
                  </Typography>
                  <Typography
                    sx={{
                      color: themeStatus === 'dark' ? '#919eab' : 'black',
                      fontWeight: 600,
                      fontSize: '18px',
                      ml: 1,
                      mt: 1,
                    }}
                    variant="h6"
                  >
                    is to build a compassionate and connected healthcare community, inspiring excellence and innovation
                    through accreditation. We empower professionals and organizations to deliver exceptional healthcare
                    solutions, positively impacting patients globally. Together, we celebrate contributions and drive
                    towards a future of better healthcare outcomes.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export function formatAccreditationDate(dateStr) {
  if (dateStr) {
    try {
      // Parse the input date string
      const inputDate = new Date(dateStr);

      // Check if the date is valid
      if (Number.isNaN(inputDate.getTime())) {
        throw new Error('Invalid date');
      }

      // Format the date in the desired output format
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      const outputDate = inputDate.toLocaleDateString('en-US', options);

      return outputDate;
    } catch (error) {
      // Handle invalid date format
      console.error(`Error: ${error.message}`);
      return null;
    }
  } else {
    return '';
  }
}

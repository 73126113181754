import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  SwipeableDrawer,
  Tab,
  Tabs,
  Typography,
  useTheme,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DrawerItem from './DrawerItem';
import { getDocumentData } from '../../../store/actions/files';
import StandardStatus from './StandardStatus';
import StandardChildStatus from './StandardChildStatus';
import ParentAccreditationDetails from './ParentAccreditationDetails';
import { AccreditationContext } from '../index';
import InformationForm from './InformationForm';

export default function StandardsList() {
  const { standards, userData, selectedDocument, accreditationData } = useContext(AccreditationContext);
  const anchor = 'right';
  const dispatch = useDispatch();
  const [state, setState] = useState({
    right: false,
  });
  const [childItemDetail, setChildItemDetails] = useState({});
  const [selectedChild, setSelectedChild] = useState(0);
  const [selectedStandard, setSelectedStandard] = useState(0);
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  const [notificationDocument, setNotificationDocument] = useState(selectedDocument);

  const toggleDrawer = (anchor, open, childItem, childIndex, standardIndex) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
    setChildItemDetails(childItem);
    setSelectedChild(childIndex);
    setSelectedStandard(standardIndex);
  };

  useEffect(() => {
    const fetchData = async () => {
      if (notificationDocument && notificationDocument.id && standards) {
        try {
          const notificationSelectedStandard = standards[notificationDocument.standard_id - 1];
          const notificationSelectedChildStandard =
            notificationSelectedStandard.child_standards[notificationDocument.standard_child_id - 1];
          setState({ ...state, [anchor]: true });
          setChildItemDetails(notificationSelectedChildStandard);
          setSelectedChild(notificationDocument.standard_child_id);
          setSelectedStandard(notificationDocument.standard_id);
        } catch (error) {
          console.error('error operning document notification', error);
        }
      }
    };

    fetchData(); // Call the fetchData function when the component mounts or when selectedDocument changes

    return () => {
      setNotificationDocument(null);
    };
  }, [selectedDocument]);

  useEffect(() => {
    const fetchDocumentData = async () => {
      try {
        dispatch(getDocumentData(accreditationData.id, selectedStandard, selectedChild));
      } catch (error) {
        console.error('Error fetching document data:', error);
      }
    };

    if (state.right) {
      fetchDocumentData();
    }
  }, [selectedChild]);

  return (
    <>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {standards && standards.length >= 1 ? (
          <>
            <Grid item md={12} sm={12} sx={{ width: '100%' }}>
              <Card
                style={{
                  backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
                  color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <ParentAccreditationDetails userData={userData} accreditationData={accreditationData} />
              </Card>
            </Grid>

            {standards.map((item, index) => (
              <Grid key={index} item md={12} sm={12} xs={12}>
                <Accordion
                  sx={{
                    backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
                    color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <StandardStatus item={item} index={index} />
                  </AccordionSummary>
                  <AccordionDetails>
                    {item.child_standards &&
                      item.child_standards.length >= 1 &&
                      item.child_standards
                        .map((childItems, indexTwo) => {
                          const originalIndexTwo = item.child_standards.length - indexTwo - 1;
                          return (
                            <>
                              <Card
                                sx={{
                                  m: 2,
                                  backgroundColor:
                                    themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
                                  color:
                                    themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                                }}
                              >
                                <CardActionArea
                                  onClick={toggleDrawer('right', true, childItems, originalIndexTwo + 1, index + 1)}
                                >
                                  <CardContent
                                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                  >
                                    <Box>
                                      <Typography variant="h6">
                                        Standard {index + 1}.{originalIndexTwo + 1}
                                      </Typography>
                                      <Typography>{childItems.title}</Typography>
                                    </Box>
                                    <StandardChildStatus item={childItems.status} />
                                  </CardContent>
                                </CardActionArea>
                              </Card>
                            </>
                          );
                        })
                        .reverse()}
                  </AccordionDetails>
                </Accordion>
              </Grid>
            ))}
          </>
        ) : (
          <>
            <Grid item md={12} sx={{ width: '100%' }}>
              <Card>
                <CardContent>
                  <Typography variant="h5">Error no standards Found</Typography>
                  <Typography variant="">Please refresh the page or try again later.</Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
      <SwipeableDrawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false, {}, 0)}>
        <DrawerItem
          childItemDetail={childItemDetail}
          selectedChildIndex={selectedChild}
          selectedStandard={selectedStandard}
          toggleDrawer={toggleDrawer}
        />
      </SwipeableDrawer>
    </>
  );
}

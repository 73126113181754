import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdditionalUserInfo } from '../store/actions/users';

export function useGetUserData() {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const userDataLoading = useSelector((state) => state.user.userDataLoading);

  useEffect(() => {
    dispatch(getAdditionalUserInfo());
  }, []);

  return { userData, userDataLoading };
}

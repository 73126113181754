import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import {
  UPLOAD_FILES,
  GET_DOCUMENT,
  DELETE_FILE,
  GET_FILES,
  GETTING_FILES,
  REMOVE_NEW_FILES,
  ADD_NEW_FILES,
  ADD_NEW_FILES_CHILD,
} from '../types';
import { FILE_SUCCESSFULLY_UPLOADED_EMAIL } from '../API';

export const uploadedDocumentsEmail = (postData) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  try {
    axios
      .post(FILE_SUCCESSFULLY_UPLOADED_EMAIL, postData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
      })
      .then((res) => {
        console.log('File Uploaded');
      });

    dispatch();
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};

import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Box, CardContent, Card } from '@mui/material';
import { useSelector } from 'react-redux';

// components
import { withAuth } from '../../components/HOC/withAuth';
import { useGetUserData } from '../../hooks/useGetUserData';
import { getFormattedName } from '../../helpers/getFormattedName';
import HomeCard from './helpers/HomeCard';
import CustomLoader from '../../components/ui/CustomLoader';
// ----------------------------------------------------------------------

function DashboardAppPage() {
  const { userData, userDataLoading } = useGetUserData();
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  if (userDataLoading) {
    return <CustomLoader />;
  }

  return (
    <>
      <Helmet>
        <title> ACMA Accreditation </title>
      </Helmet>

      <Container maxWidth="xl">
        <Box sx={{ mb: 2 }}>
          <Card
            style={{
              backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,

              color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <CardContent
              style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
            >
              <Box>
                <Typography variant="h4">Welcome, {userData && getFormattedName(userData)}👋</Typography>
                <Typography>
                  {(userData && userData.accreditation_type_header_sub_title) ||
                    "ACMA's Organizational Accreditation Portal"}
                </Typography>
              </Box>
              <img src={userData && userData.company_logo} alt="company_logo" style={{ width: '20%' }} />
            </CardContent>
          </Card>
        </Box>

        <Box sx={{ pt: 2 }}>
          <HomeCard userData={userData} />
        </Box>
      </Container>
    </>
  );
}
export default withAuth(DashboardAppPage);

import { Box, Typography } from '@mui/material';
import { sentenceCase } from 'change-case';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import Label from '../../../components/label/Label';
import { formatString } from '../../../helpers/formatString';

export default function StandardStatus({ item, index }) {
  const HtmlTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
    ({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
      },
    })
  );

  return (
    <Box>
      {item.status === 'Under Review' ? (
        <>
          <Label color={'warning'}>{sentenceCase('Under Review')}</Label>
        </>
      ) : item.status === 'Completed' ? (
        <>
          <HtmlTooltip
            title={
              <>
                <Typography color="inherit">Completed</Typography>
                <Typography sx={{ fontSize: 13 }}>{item.standard_completion_date}</Typography>
              </>
            }
          >
            <Label color={'success'}>{sentenceCase('Completed')}</Label>
          </HtmlTooltip>{' '}
        </>
      ) : (
        <></>
      )}
      <Typography sx={{ mt: 1 }} variant="h6">
        Standard {index + 1}
      </Typography>
      <Typography sx={{ width: '100%', flexShrink: 0 }}>{formatString(item.standard, 50)}</Typography>
    </Box>
  );
}

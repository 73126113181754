import { Box, Card, CardContent, Container, Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export default function BenefitsCard() {
  const themeStatus = useSelector((state) => state.theme.themeStatus);

  const cardContent = [
    {
      id: 1,
      title: 'Enhanced Reputation',
      description:
        'Harmonize your training initiatives with the renowned standard for quality education in the pharmaceutical industry. The ACMA stands out as a leader within the industry, acknowledged for its leadership.',
      icon: 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/certification-badge.svg',
    },
    {
      id: 2,
      title: 'Framework for Program Structures',
      description:
        'Through 8 standards, organizations are able to boost efficiency, identify improvements, and manage program structure.',
      icon: 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/operations.svg',
    },
    {
      id: 3,
      title: 'Objective Assessment',
      description:
        'By accrediting training programs through the ACMA, an accreditation review provides an objective assessment of training program quality.',
      icon: 'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/user.svg',
    },
  ];
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={12} className="flex justify-center align-center">
          <Typography
            variant="h2"
            className="text-center"
            sx={{ width: '50%', color: themeStatus === 'dark' ? 'white' : 'black' }}
          >
            Why Choose ACMA's Accreditation?
          </Typography>
        </Grid>
        {cardContent &&
          cardContent.length >= 1 &&
          cardContent.map((item) => (
            <>
              <Grid item md={4} sx={{ mt: 5 }} container>
                <Card
                  sx={{
                    padding: '30px',
                    backgroundColor: themeStatus === 'dark' ? '#161c24' : 'white',
                    color: themeStatus === 'dark' ? 'white' : 'black',
                    boxShadow: 'none',
                    '&:hover': {
                      background: themeStatus === 'dark' ? '#161c24' : 'white',
                      boxShadow: '1px 6px 30px 0px rgba(6,8,10,1)',
                    },
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: 'center', justifyContent: 'center', alignItems: 'center' }}>
                      <img src={item.icon} alt={item.title} width={'20%'} />
                    </Box>
                    <Box>
                      <Typography sx={{ mt: 5, fontSize: '1.25rem', fontWeight: 700 }} className="text-center">
                        {item.title}
                      </Typography>
                      <Typography
                        className="text-center"
                        sx={{ mt: 3, color: themeStatus === 'dark' ? '#919eab' : 'black' }}
                      >
                        {item.description}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ))}
      </Grid>
    </Container>
  );
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAccreditationDetail } from '../store/actions/accreditation';

export function useGetAccreditationDetail(accreditationId) {
  const dispatch = useDispatch();
  const accreditationDataLoading = useSelector((state) => state.accreditation.accreditationDataLoading);
  const accreditationData = useSelector((state) => state.accreditation.accreditationData);

  useEffect(() => {
    dispatch(getAccreditationDetail(accreditationId));
  }, [dispatch]);

  return { accreditationData, accreditationDataLoading };
}

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStandards } from '../store/actions/standards';

export function useGetStandards(accreditationId) {
  const dispatch = useDispatch();
  const standards = useSelector((state) => state.standards.standards);
  const standardsLoading = useSelector((state) => state.standards.standardsLoading);

  useEffect(() => {
    dispatch(getStandards(accreditationId));
  }, []);

  return { standards, standardsLoading };
}

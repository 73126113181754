import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Badge,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  Typography,
  useTheme,
} from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetNotifications } from '../../../hooks/useGetNotifications';
import CustomLoader from '../../../components/ui/CustomLoader';
import DocumentNotification from './DocumentNotification';
import Label from '../../../components/label/Label';
import StandardNotification from './StandardNotification';
import ChildStandardNotification from './ChildStandardNotification';

export default function NotificationAccordion({ userData }) {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  const { notifications, notificationsLoading } = useGetNotifications(
    userData.company_id,
    'some',
    userData.accreditation_type
  );

  if (notificationsLoading) {
    return <CustomLoader />;
  }
  return (
    <Box sx={{ mt: 3 }}>
      <Accordion
        sx={{
          backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
          color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box className="flex justify-center align-center">
            <Typography variant="h6">Notifications</Typography>
            {notifications.length <= 5 ? (
              <Label sx={{ ml: 1 }} color={'warning'}>
                <NotificationsIcon /> +{notifications.length}
              </Label>
            ) : notifications.length > 5 ? (
              <>
                <Label sx={{ ml: 1 }} color={'warning'}>
                  +5
                </Label>
              </>
            ) : (
              <></>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <Card
                sx={{
                  border: 'solid 1px gray',
                  backgroundColor: 'transparent',
                  color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                }}
              >
                <CardContent>
                  <Typography variant="h5">Your Notifcations</Typography>
                  {notifications && notifications.length >= 1 ? (
                    <>
                      {notifications.map((item) => (
                        <>
                          {item.notification_type === 'Document' ? (
                            <>
                              <DocumentNotification notification={item} />
                            </>
                          ) : item.notification_type === 'Standard' ? (
                            <>
                              <StandardNotification notification={item} />
                            </>
                          ) : item.notification_type === 'Child Standard' ? (
                            <>
                              <ChildStandardNotification notification={item} />
                            </>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </>
                  ) : (
                    <>
                      <Card
                        sx={{
                          mt: 2,
                          mb: 2,
                          background: 'transparent',
                          boxShadow: 'none',
                          border: 'solid 1px gray',
                          color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                        }}
                      >
                        <CardContent>No Notifications Avaliable</CardContent>
                      </Card>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

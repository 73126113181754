import { GETTING_FILES, CLEAR_STATE, GET_FILES, ADD_NEW_FILES, REMOVE_NEW_FILES, DELETE_FILE } from '../types';

const initialState = {
  uploadedFiles: [],
  newlyAddedFiles: [],
  filesLoading: false,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STATE:
      return {
        ...state,
        uploadedFiles: [],
        newlyAddedFiles: [],
        filesLoading: false,
      };
    case ADD_NEW_FILES:
      return {
        ...state,
        uploadedFiles: [...state.uploadedFiles, ...action.payload],
      };
    case REMOVE_NEW_FILES:
      return {
        ...state,
        newlyAddedFiles: [],
      };
    case GETTING_FILES:
      return {
        ...state,
        filesLoading: action.payload,
      };
    case GET_FILES:
      return {
        ...state,
        uploadedFiles: action.payload,
      };
    case DELETE_FILE: {
      const { standardChildId, standardId, accreditationId, fileName } = action.payload;
      const updatedUploadedFiles = state.uploadedFiles.filter((file) => {
        return (
          file.standard_child_id !== standardChildId ||
          file.standard_id !== standardId ||
          file.accreditation_id !== accreditationId ||
          file.name !== fileName
        );
      });
      return {
        ...state,
        uploadedFiles: updatedUploadedFiles,
      };
    }
    default:
      return state;
  }
}

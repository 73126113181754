import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Container,
  IconButton,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import toast from 'react-hot-toast';

import { useDispatch, useSelector } from 'react-redux';
import InfoIcon from '@mui/icons-material/Info';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CloseIcon from '@mui/icons-material/Close';
import useResponsive from '../../../hooks/useResponsive';
import { addNewFiles, uploadFiles } from '../../../store/actions/files';
import { getStandards } from '../../../store/actions/standards';
import { statusChip } from '../../../helpers/statusChip';
import StandardChildStatus from './StandardChildStatus';
import UploadedFiles from './UploadedFiles';
import { AccreditationContext } from '..';
import { formatString } from '../../../helpers/formatString';
import Requirement from './Requirements';

export default function DrawerItem({ childItemDetail, selectedChildIndex, selectedStandard, toggleDrawer }) {
  const { accreditationData } = useContext(AccreditationContext);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const [uploadingLoader, setUploadingLoader] = useState(false);

  const isDesktop = useResponsive('up', 'md');
  const dispatch = useDispatch();
  const company = useSelector((state) => state.user.userData.company_name);

  const files = useSelector((state) => state.files.uploadedFiles);
  const filesLoading = useSelector((state) => state.files.filesLoading);
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();

  const drawerSize = isDesktop ? '500px' : '350px';

  // File change handler
  const handleFileChange = (event) => {
    const { files } = event.target;
    const allowedTypes = [
      'application/pdf',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ];

    const selected = Array.from(files).filter((file) => allowedTypes.includes(file.type));

    const filesWithRandomNames = selected.map((file) => {
      const fileParts = file.name.split('.');
      const fileNameWithoutExtension = fileParts.slice(0, -1).join('.');
      const fileExtension = fileParts[fileParts.length - 1];
      const randomNumber = Math.floor(Math.random() * 1000);
      const fileNameWithRandomNumber = `${fileNameWithoutExtension}_${randomNumber}.${fileExtension}`;
      return new File([file], fileNameWithRandomNumber, { type: file.type });
    });

    setSelectedFiles(filesWithRandomNames);
  };

  // Remove file from selectedFiles
  const removeFile = (file) => {
    setSelectedFiles((prevSelectedFiles) => prevSelectedFiles.filter((selectedFile) => selectedFile !== file));
  };

  // File upload handler
  const handleFileUpload = async () => {
    if (selectedFiles.length >= 1) {
      try {
        setUploadingLoader(true);
        dispatch(
          uploadFiles(
            selectedFiles,
            company,
            selectedStandard,
            selectedChildIndex,
            childItemDetail.id,
            accreditationData.id,
            accreditationData.accreditation_type
          )
        );
        dispatch(
          addNewFiles(
            selectedFiles,
            company,
            selectedStandard,
            selectedChildIndex,
            childItemDetail.id,
            accreditationData.id
          )
        );

        setSelectedFiles([]);

        setTimeout(() => {
          setShowSuccessToast(true);
        }, 4000);

        getStandards(accreditationData.id);
      } catch (error) {
        toast.error('Error uploading files. Please try again.');
      }
    } else {
      toast.error('Please add a file to continue.');
    }
  };

  useEffect(() => {
    if (showSuccessToast) {
      toast.success('Document(s) Successfully Uploaded');
      // Reset the state after displaying the toast
      setUploadingLoader(false);
      setShowSuccessToast(false);
    }
  }, [showSuccessToast]);

  useEffect(() => {}, [files]);

  return (
    <Container
      sx={{
        overflowY: 'scroll',
        backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
        height: '100%',
        color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
      }}
    >
      <Box
        sx={{
          maxWidth: drawerSize,
          width: drawerSize,
          mt: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <IconButton aria-label="delete" size="large" onClick={toggleDrawer('right', false, {})}>
          <CloseIcon
            style={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
          />
        </IconButton>

        <StandardChildStatus item={childItemDetail.status} />
      </Box>

      <Box
        sx={{
          mt: 2,
          pb: 2,
          borderBottom: `solid ${
            themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color
          } 1px`,
        }}
      >
        <Typography
          variant="h6"
          sx={{ color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color }}
        >
          Standard {selectedStandard}.{selectedChildIndex}
        </Typography>
        <Typography
          sx={{
            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
            maxWidth: drawerSize,
            width: drawerSize,
          }}
        >
          {childItemDetail.title}
        </Typography>
      </Box>

      <Box sx={{ mt: 4, maxWidth: drawerSize, width: drawerSize }}>
        <Card
          sx={{
            backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.light,
            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
          }}
        >
          <CardContent>
            <Requirement childItemDetail={childItemDetail} />
            <Typography sx={{ mt: 2, color: '#919eab', fontSize: '15px' }}>
              Please keep your files to "PDF", 'DOC' or "XLS"
            </Typography>
          </CardContent>
        </Card>

        {filesLoading ? (
          <>
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={120}
              sx={{ background: 'gray', mt: 2, borderRadius: '10px' }}
            />
          </>
        ) : (
          <>
            <Card
              sx={{
                mt: 2,
                mb: 3,
                backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.light,
                color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
              }}
            >
              <CardContent>
                <Box sx={{ mb: 2 }} className="flex align-center">
                  <Typography variant="h6">Upload Required Documents</Typography>
                  <Tooltip
                    placement="top"
                    title="To select multiple documents hold `ctrl` and right click the files."
                    sx={{ ml: 1 }}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                {uploadingLoader ? (
                  <>
                    <Card>
                      <CardContent className="flex justify-center align-center">
                        <CircularProgress />
                        <Typography sx={{ ml: 2 }}>
                          <b>Document uploading in progress. Please wait and avoid closing this sidebar</b>
                        </Typography>
                      </CardContent>
                    </Card>
                  </>
                ) : (
                  <>
                    <UploadedFiles company={company} files={files} />
                  </>
                )}

                {childItemDetail && childItemDetail.status === 'Completed' ? (
                  <></>
                ) : (
                  <>
                    <Button
                      variant="contained"
                      component="label"
                      fullWidth
                      size="large"
                      style={{
                        marginTop: '10px',
                        backgroundColor: 'transparent',
                        border: `dashed 2px ${
                          themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color
                        }`,
                        boxShadow: 'none',
                        height: '80px',
                        color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                      }}
                    >
                      Upload File
                      <input
                        multiple
                        accept=".pdf,.docx,.xls,.xlsx,.csv"
                        type="file"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                    <Typography sx={{ mt: 2, color: '#919eab', fontSize: '15px' }}>
                      Please Note : after uploading a file it may take a few minutes before viewing the file
                    </Typography>
                  </>
                )}

                <Box sx={{ mt: 3 }}>
                  {selectedFiles.map((file, index) => (
                    <Card
                      key={index}
                      sx={{
                        backgroundColor:
                          themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
                        color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                        mt: 1,
                        border: `solid 1px ${
                          themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color
                        }`,
                        boxShadow: 'none',
                        pb: 0,
                      }}
                    >
                      <CardContent
                        className="flex justify-between align-center"
                        style={{ paddingBottom: '0px', padding: '10px' }}
                      >
                        <Box>
                          {statusChip(file.document_status)}
                          <Typography>{formatString(file.name, 25)}</Typography>
                        </Box>
                        <IconButton onClick={() => removeFile(file)}>
                          <DeleteForeverIcon />
                        </IconButton>
                      </CardContent>
                    </Card>
                  ))}
                </Box>

                {childItemDetail && childItemDetail.status === 'Completed' ? (
                  <></>
                ) : (
                  <>
                    <LoadingButton
                      loading={uploadingLoader}
                      loadingIndicator="Uploading..."
                      variant="outlined"
                      size="large"
                      fullWidth
                      sx={{
                        mt: 2,
                        backgroundColor: '#014459',
                        color: 'white',
                        '&:hover': { backgroundColor: '#051d23' },
                      }}
                      onClick={handleFileUpload}
                    >
                      Save Changes
                    </LoadingButton>
                  </>
                )}
              </CardContent>
            </Card>
          </>
        )}
      </Box>
    </Container>
  );
}

import { createContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Container } from '@mui/material';

import { useGetStandards } from '../../hooks/useGetStandards';
import { withAuth } from '../../components/HOC/withAuth';
import StandardsList from './helpers/StandardsList';
import { useGetUserData } from '../../hooks/useGetUserData';
import CustomLoader from '../../components/ui/CustomLoader';
import { useGetAccreditationDetail } from '../../hooks/useGetAccreditationDetail';
import ExpiredAccreditation from './helpers/ExpiredAccreditation';

export const AccreditationContext = createContext(null);

function Accreditation() {
  const { accreditationId } = useParams();
  const { standards, standardsLoading } = useGetStandards(accreditationId);
  const { accreditationData, accreditationDataLoading } = useGetAccreditationDetail(accreditationId);
  const { userData, userDataLoading } = useGetUserData();
  const { state } = useLocation();
  const selectedDocument = state?.document || null;

  const endDate = new Date(accreditationData.accreditation_end_date);
  const completedDate = accreditationData.accreditation_date_completed
    ? new Date(accreditationData.accreditation_date_completed)
    : null;

  const today = new Date();

  if (standardsLoading || userDataLoading || accreditationDataLoading) {
    return <CustomLoader />;
  }

  const renderComponent = () => {
    if (completedDate) {
      return <StandardsList />;
    }

    if (endDate < today) {
      return <ExpiredAccreditation />;
    }

    return <StandardsList />;
  };

  return (
    <>
      <Helmet>
        <title> Accreditation </title>
      </Helmet>
      <Container maxWidth="xl">
        <Box>
          <AccreditationContext.Provider value={{ standards, userData, selectedDocument, accreditationData }}>
            {renderComponent()}
          </AccreditationContext.Provider>
        </Box>
      </Container>
    </>
  );
}

export default withAuth(Accreditation);

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Button,
  Tooltip,
  Divider,
  Popover,
  Typography,
  IconButton,
  ListSubheader,
  useTheme,
} from '@mui/material';
// utils
// components
import { useDispatch, useSelector } from 'react-redux';
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import { useGetNotifications } from '../../../hooks/useGetNotifications';
import { useGetUserData } from '../../../hooks/useGetUserData';
import CustomLoader from '../../../components/ui/CustomLoader';
import DocumentNavNotification from './DocumentNavNotification';
import ChildStandardNavNotification from './ChildStandardNavNotification';
import StandardNavNotification from './StandardNavNotification';
import { markallNotificationsRead } from '../../../store/actions/notifications';

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const { userData, userDataLoading } = useGetUserData();

  // if (userDataLoading) {
  //   return <CustomLoader />;
  // }

  return <NotificationsPopoverContent userData={userData} />;
}

function NotificationsPopoverContent({ userData }) {
  const { notifications, notificationsLoading } = useGetNotifications(userData?.company_id, 'some');
  const [someNotification, setSomeNotifications] = useState([]);
  const [open, setOpen] = useState(null);
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (userData && someNotification) {
      setSomeNotifications(notifications);
    }
  }, [userData, notifications]);

  const totalUnRead =
    (someNotification &&
      someNotification.legnth >= 1 &&
      someNotification.filter((item) => item.is_seen === 0).length) ||
    0;

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMarkAllAsRead = () => {
    dispatch(markallNotificationsRead());
  };

  // if (notificationsLoading) {
  //   return <CustomLoader />;
  // }
  return (
    <>
      <IconButton
        color={open ? 'primary' : 'default'}
        onClick={handleOpen}
        sx={{
          width: 40,
          height: 40,
          backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
          color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
        }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" />
        </Badge>
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title=" Mark all as read & delete">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Scrollbar sx={{ height: { xs: 340, sm: 'auto' } }}>
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                New
              </ListSubheader>
            }
          >
            {someNotification &&
              someNotification.length >= 1 &&
              someNotification.map((notification) => (
                <NotificationItem key={notification.id} notification={notification} setOpen={setOpen} />
              ))}
          </List>
          {/* 
          <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {someNotification.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
        </Scrollbar>

        <Divider sx={{ borderStyle: 'dashed' }} />

        {/* <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </Popover>
    </>
  );
}

// ----------------------------------------------------------------------

function NotificationItem({ notification, setOpen }) {
  const handleOpenOutside = () => {
    setOpen(false);
  };

  return !notification || !notification.inner_notification || !notification.inner_notification.id ? (
    <></>
  ) : (
    <>
      {notification.notification_type === 'Document' ? (
        <>
          <DocumentNavNotification handleOpenOutside={handleOpenOutside} notification={notification} />
        </>
      ) : notification.notification_type === 'Standard' ? (
        <>
          <StandardNavNotification handleOpenOutside={handleOpenOutside} notification={notification} />
        </>
      ) : notification.notification_type === 'Child Standard' ? (
        <>
          <ChildStandardNavNotification handleOpenOutside={handleOpenOutside} notification={notification} />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

// function renderContent(notification) {
//   const title = (
//     <Typography variant="subtitle2">
//       {notification.title}
//       <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
//         &nbsp; {noCase(notification.description)}
//       </Typography>
//     </Typography>
//   );

//   if (notification.type === 'order_placed') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/ic_notification_package.svg" />,
//       title,
//     };
//   }
//   if (notification.type === 'order_shipped') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/ic_notification_shipping.svg" />,
//       title,
//     };
//   }
//   if (notification.type === 'mail') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/ic_notification_mail.svg" />,
//       title,
//     };
//   }
//   if (notification.type === 'chat_message') {
//     return {
//       avatar: <img alt={notification.title} src="/assets/icons/ic_notification_chat.svg" />,
//       title,
//     };
//   }
//   return {
//     avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
//     title,
//   };
// }

import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getNotifications } from '../store/actions/notifications';

export function useGetNotifications(companyId, type, accreditationType) {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notification.notifications);
  const notificationsLoading = useSelector((state) => state.notification.notificationsLoading);

  useEffect(() => {
    dispatch(getNotifications(companyId, type, accreditationType));
  }, []);

  return { notifications, notificationsLoading };
}

import PropTypes from 'prop-types';
// @mui
import EastIcon from '@mui/icons-material/East';
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Container, Button, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
// utils
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { bgBlur } from '../../../utils/cssStyles';
// components
//
import Logo from '../../../components/logo/Logo';
import useResponsive from '../../../hooks/useResponsive';
import ThemeSwitcher from '../../dashboard/header/ThemeSwitcher';

// ----------------------------------------------------------------------

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 92;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  padding: 0,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav }) {
  const isDesktop = useResponsive('up', 'md');
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <StyledRoot
      sx={{
        backgroundColor: themeStatus === 'dark' ? '#161c24cc' : 'transparent',
        color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
      }}
    >
      <Container>
        <StyledToolbar disableGutters>
          {/* <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}
          >
            <MenuIcon />
          </IconButton> */}
          <Box sx={{ width: '15%' }}>
            <Logo />
          </Box>

          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            {/* {isDesktop ? (
              <>
                <CNMAPInfoMenu />
              </>
            ) : (
              <></>
            )} */}
            <ThemeSwitcher />

            <Button
              variant="contained"
              size="large"
              sx={{
                padding: '30px',
                borderRadius: '20px',
                backgroundColor: themeStatus === 'dark' ? 'white' : '#021f28',
                color: themeStatus === 'dark' ? 'black' : 'white',
                '&:hover': {
                  background: themeStatus === 'dark' ? '#e3e3e3' : '#021f28',
                },
              }}
              onClick={(e) => navigate('/login')}
            >
              Login <EastIcon style={{ marginLeft: '10px' }} />
            </Button>
          </Stack>
        </StyledToolbar>
      </Container>
    </StyledRoot>
  );
}

import { GET_USER_DETAILS, CLEAR_STATE, GET_COMPANY_USERS } from '../types';

const initialState = {
  userData: [],
  userDataLoading: true,
  companyUsers: [],
  companyUsersLoading: true,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STATE:
      return {
        ...state,
        userData: [],
        userDataLoading: true,
        companyUsers: [],
        companyUsersLoading: true,
      };
    case GET_COMPANY_USERS:
      return {
        ...state,
        companyUsers: action.payload,
        companyUsersLoading: false,
      };
    case GET_USER_DETAILS:
      return {
        ...state,
        userDataLoading: false,
        userData: action.payload,
      };
    default:
      return state;
  }
}

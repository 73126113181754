import { useContext } from 'react';
import { Box, Button, Card, CardContent, Typography, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import DocumentImg from './DocumentImg';
import { formatString } from '../../../helpers/formatString';
import { getFile } from '../../../store/actions/files';
import { statusChip } from '../../../helpers/statusChip';
import DeleteDocument from './DeleteDocument';
import Rejected from './Rejected';
import { AccreditationContext } from '..';

export default function UploadedFiles({ company, files }) {
  const { accreditationData } = useContext(AccreditationContext);

  const dispatch = useDispatch();
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();

  const getSelectedFile = async (fileName) => {
    dispatch(getFile(company, fileName, accreditationData.accreditation_type, accreditationData.id));
  };

  return (
    <>
      {files && files.length >= 1 ? (
        <>
          <Typography sx={{ mt: 1, mb: 1, color: '#919eab', fontSize: '15px' }}>Previously Uploaded Files</Typography>

          {files.map((item2, index) => (
            <>
              <Card
                key={index}
                sx={{
                  backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.dark : theme.palette.lightbg.dark,
                  color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                  mt: 1,
                  border: 'solid 1px gray',
                  boxShadow: 'none',
                  pb: 0,
                }}
              >
                <CardContent
                  className="flex justify-between align-center"
                  style={{ paddingBottom: '0px', padding: '10px' }}
                >
                  <Box className="flex">
                    <DocumentImg name={item2.name} />
                    <Box sx={{ ml: 2 }}>
                      <Button
                        sx={{
                          color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                        }}
                        onClick={() => getSelectedFile(item2.name)}
                      >
                        <Typography variant="h6">{formatString(item2.name, 25)}</Typography>
                      </Button>

                      <Box className="flex align-center">
                        <Typography sx={{ fontSize: '12px', mr: 1, color: '#919eab', pl: 1 }}>
                          {formatString(item2.uploaded_by, 20)} | {item2.upload_date}
                        </Typography>
                        {statusChip(item2.document_status)}
                      </Box>
                    </Box>
                  </Box>
                  <Box>
                    <DeleteDocument document={item2} company={company} />
                  </Box>
                </CardContent>
                <Box>
                  <Rejected message={item2.message} />
                </Box>
              </Card>
            </>
          ))}
        </>
      ) : (
        <></>
      )}
    </>
  );
}

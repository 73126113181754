import { GET_NOTIFICATIONS, CLEAR_STATE } from '../types';

const initialState = {
  notifications: [],
  notificationsLoading: true,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STATE:
      return {
        ...state,
        notifications: [],
        notificationsLoading: true,
      };
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notificationsLoading: false,
        notifications: action.payload,
      };
    default:
      return state;
  }
}

import { Chip } from '@mui/material';
import Label from '../components/label/Label';

export function statusChip(label) {
  if (label === 'Under Review') {
    return (
      <>
        <Label color="warning">Under Review</Label>
      </>
    );
  }
  if (label === 'Approved') {
    return (
      <>
        <Label color="success">Approved</Label>
      </>
    );
  }
  if (label === 'Rejected') {
    return (
      <>
        <Label color="error">Rejected</Label>
      </>
    );
  }
  return '';
}

import { Typography, Box } from '@mui/material';

export default function Requirement({ childItemDetail }) {
  return (
    <Box>
      {childItemDetail && childItemDetail.standard_child_name ? (
        <Box sx={{ mt: 1 }}>
          <Typography variant="h6">Standard Description</Typography>
          <Typography sx={{ mt: 1 }}>{childItemDetail.standard_child_name}</Typography>
        </Box>
      ) : (
        <></>
      )}

      {childItemDetail && childItemDetail.guidance ? (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">Guidance</Typography>
          <Typography sx={{ mt: 1 }} dangerouslySetInnerHTML={{ __html: childItemDetail.guidance }} />
        </Box>
      ) : (
        <></>
      )}

      {childItemDetail && childItemDetail.requirements ? (
        <Box sx={{ mt: 3 }}>
          <Typography variant="h6">What to Upload?</Typography>
          <Typography sx={{ mt: 1 }} dangerouslySetInnerHTML={{ __html: childItemDetail.requirements }} />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
}

import {
  Box,
  Card,
  CardContent,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { useGetCompanyUsers } from '../../../hooks/useGetCompanyUsers';
import { getFormattedName } from '../../../helpers/getFormattedName';
import Label from '../../../components/label/Label';
import CustomLoader from '../../../components/ui/CustomLoader';

export default function UserTable({ userData }) {
  const navigate = useNavigate();
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();
  const { companyUsers, companyUsersLoading } = useGetCompanyUsers(userData.company_id);

  useEffect(() => {
    if (!userData || !userData.company_id) {
      navigate('/dashboard');
    }
  }, [userData]);

  if (companyUsersLoading) {
    return <CustomLoader />;
  }

  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Card
          style={{
            backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,

            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <CardContent
            style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Box>
              <Typography variant="h4">Welcome, {userData && getFormattedName(userData)}👋</Typography>
              <Typography>
                {userData.company_name} {userData.accreditation_type_detail}
              </Typography>
            </Box>
            <img src={userData && userData.company_logo} alt="company_logo" style={{ width: '20%' }} />
          </CardContent>
        </Card>
      </Box>

      <Box sx={{ mt: 5 }}>
        <Card
          sx={{
            backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
          }}
        >
          <CardContent>
            <Typography variant="h6">Company Users</Typography>
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table
                sx={{
                  minWidth: 650,
                  backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
                  color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                }}
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Full Name</TableCell>
                    <TableCell align="right">Email</TableCell>
                    <TableCell align="right">Role</TableCell>
                    <TableCell align="right">Status</TableCell>
                    <TableCell align="right">Date Created</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {companyUsers &&
                    companyUsers.map((row) => (
                      <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{
                            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                          }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          component="th"
                          align="right"
                          sx={{
                            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                          }}
                        >
                          {row.email}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                          }}
                        >
                          {row.role}
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                          }}
                        >
                          <Label color={row.status === 'Revoked' ? 'error' : 'success'}>{row.status}</Label>
                        </TableCell>
                        <TableCell
                          align="right"
                          sx={{
                            color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
                          }}
                        >
                          {row.user_date_created}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}

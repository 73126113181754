import { GET_STANDARDS, CLEAR_STATE, ADD_NEW_FILES, ADD_NEW_FILES_CHILD } from '../types';

const initialState = {
  standards: [],
  standardsLoading: true,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STATE:
      return {
        ...state,
        standards: [],
        standardsLoading: true,
      };
    case GET_STANDARDS:
      return {
        ...state,
        standardsLoading: false,
        standards: action.payload,
      };
    case ADD_NEW_FILES: {
      const payload = action.payload;
      const updatedStandards = state.standards.map((standard) => {
        const matchingFiles = payload.filter((file) => file.standard_id === (standard && standard.id));

        if (matchingFiles.length > 0 && !Object.prototype.hasOwnProperty.call(standard, 'status')) {
          return {
            ...standard,
            status: 'Under Review',
          };
        }

        return standard;
      });

      return {
        ...state,
        standards: updatedStandards,
      };
    }

    case ADD_NEW_FILES_CHILD: {
      const { standardId, standardChildId } = action.payload;

      const updatedStandards = state.standards.map((standard) => {
        if (standard.id === standardId) {
          const updatedChildStandards = standard.child_standards.map((childStandard) => {
            if (
              childStandard.id === standardChildId &&
              !Object.prototype.hasOwnProperty.call(childStandard, 'status')
            ) {
              return {
                ...childStandard,
                status: 'Under Review',
              };
            }
            return childStandard;
          });

          return {
            ...standard,
            child_standards: updatedChildStandards,
          };
        }
        return standard;
      });
      return {
        ...state,
        standards: updatedStandards,
      };
    }

    default:
      return state;
  }
}

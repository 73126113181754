import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/dashboard/DashboardAppPage';
import Accreditation from './pages/accreditation';
import ForgotPassword from './pages/forgotPassword';
import CompanyUsers from './pages/companyUsers';
import Home from './pages/unAuth/home';
import UnAuthLayout from './layouts/unAuth/UnAuthLayout';

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      element: <UnAuthLayout />,
      children: [{ path: '/', element: <Home /> }],
    },
    {
      element: <DashboardLayout />,
      children: [
        { path: 'accreditation/:accreditationId', element: <Accreditation /> },
        { path: 'dashboard', element: <DashboardAppPage /> },
        { path: 'user', element: <CompanyUsers /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'blog', element: <BlogPage /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPassword />,
    },
    {
      path: '/*',
      element: <Page404 />,
    },
  ]);

  return routes;
}

import { Card, CardContent, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import AccreditationAccordion from './AccreditationAccordion';
import NotificationAccordion from './NotificationAccordion';

export default function HomeCard({ userData }) {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();

  return (
    <Card
      sx={{
        minHeight: '65vh',
        backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
        color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
      }}
    >
      <CardContent>
        {/* Accreditations */}
        <AccreditationAccordion userData={userData} />
        {/* Notifications */}
        <NotificationAccordion userData={userData} />
      </CardContent>
    </Card>
  );
}

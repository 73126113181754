import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCompanyUsers } from '../store/actions/companies';

export function useGetCompanyUsers(companyId) {
  const dispatch = useDispatch();
  const companyUsersLoading = useSelector((state) => state.user.companyUsersLoading);
  const companyUsers = useSelector((state) => state.user.companyUsers);

  useEffect(() => {
    dispatch(getCompanyUsers(companyId));
  }, [dispatch]);

  return { companyUsers, companyUsersLoading };
}

import { Box, Button, Container, Grid, Typography, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EastIcon from '@mui/icons-material/East';
import { useSelector } from 'react-redux';
import useResponsive from '../../../../hooks/useResponsive';

export default function HomeHeader() {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const isDesktop = useResponsive('up', 'md');
  const navigate = useNavigate();
  return (
    <Container>
      <Grid container spacing={3}>
        <Grid item md={6} className="flex justify-center column">
          <Typography sx={{ fontSize: isDesktop ? '55px' : '50px', fontWeight: 800, lineHeight: 1.25 }}>
            Prior Authorization Staffing
          </Typography>
          <Typography sx={{ width: '100%' }} variant="h2" className="animate-charcter">
            VERIFICATION
          </Typography>
          <Typography sx={{ mt: 2 }} variant="h6">
            Prior Authorization Staffing Verification Portal - Streamlining Processes for Efficient Healthcare
            Compliance
          </Typography>

          <Box sx={{ mt: 2 }}>
            <Button
              variant="contained"
              size="large"
              sx={{
                padding: '30px',
                borderRadius: '20px',
                backgroundColor: themeStatus === 'dark' ? 'white' : '#021f28',
                color: themeStatus === 'dark' ? 'black' : 'white',
                '&:hover': {
                  background: themeStatus === 'dark' ? '#e3e3e3' : '#021f28',
                },
              }}
              onClick={(e) => navigate('/login')}
            >
              Login <EastIcon style={{ marginLeft: '10px' }} />
            </Button>
          </Box>

          {/* <Typography variant="h2">Accreditation</Typography> */}
        </Grid>
        <Grid item md={6}>
          <img
            src="https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/prior+auth+staff+banner.png"
            alt="staffing-accreditation-portal"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useNavigate } from 'react-router-dom';
import DocumentImg from '../../../pages/accreditation/helpers/DocumentImg';
import { fToNow } from '../../../utils/formatTime';
import { formatString } from '../../../helpers/formatString';
import { statusChip } from '../../../helpers/statusChip';

export default function DocumentNavNotification({ notification, handleOpenOutside }) {
  const navigate = useNavigate();

  const notificationNavigate = () => {
    handleOpenOutside(false);
    navigate(`/accreditation/${notification.inner_notification.accreditation_id}`, {
      state: { document: notification.inner_notification },
    });
  };

  return (
    <ListItemButton
      onClick={notificationNavigate}
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '1px',
        ...(notification.isUnRead && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      <Box className="flex justify-center align-center">
        <DocumentImg name={notification.inner_notification.name || ''} />
        <Box sx={{ ml: 1 }}>
          <ListItemText
            primary={
              <Box className="flex justify-center align-center">
                <Typography>{formatString(notification.inner_notification.name || '', 10)}</Typography>
                <Box sx={{ ml: 1 }}>{statusChip(notification.inner_notification.document_status || '')}</Box>
              </Box>
            }
            secondary={
              <Typography
                variant="caption"
                sx={{
                  mt: 0.5,
                  display: 'flex',
                  alignItems: 'center',
                  color: 'text.disabled',
                }}
              >
                <AccessTimeIcon sx={{ mr: 0.5, width: 16, height: 16 }} />
                {fToNow(notification.timestamp)}
              </Typography>
            }
          />
        </Box>
      </Box>
    </ListItemButton>
  );
}

import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { useGetUserData } from '../../hooks/useGetUserData';
import UserTable from './helpers/UserTable';
import { withAuth } from '../../components/HOC/withAuth';
import CustomLoader from '../../components/ui/CustomLoader';

function CompanyUsers() {
  const { userData, userDataLoading } = useGetUserData();

  // if (userDataLoading) {
  //   return <CustomLoader />;
  // }

  return (
    <Container maxWidth="xl">
      <Box>
        <UserTable userData={userData} />
      </Box>
    </Container>
  );
}

export default withAuth(CompanyUsers);

import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import HomeHeader from './helpers/HomeHeader';
import BenefitsCard from './helpers/BenefitsCard';
import Mission from './helpers/Mission';
import WhyAccredit from './helpers/WhyAccredit';
import AccreditationAreas from './helpers/AccreditationAreas';

export default function Home() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>ACMA Accreditation Portal</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <Box
        sx={{
          pt: 3,
          pb: 8,
        }}
      >
        <HomeHeader />
      </Box>

      <Box
        sx={{
          pt: 10,
          pb: 10,
        }}
      >
        <BenefitsCard />
      </Box>

      {/* <Box
        sx={{
          pt: 10,
          pb: 10,
        }}
      >
        <AccreditationAreas />
      </Box> */}

      <Box
        sx={{
          pt: 10,
          pb: 10,
        }}
      >
        <WhyAccredit />
      </Box>

      <Box
        sx={{
          pt: 10,
          pb: 10,
        }}
      >
        <Mission />
      </Box>
    </>
  );
}

import { Auth } from 'aws-amplify';
import { toast } from 'react-hot-toast';
import axios from 'axios';
import { DELETE_FILE, GET_FILES, GETTING_FILES, REMOVE_NEW_FILES, ADD_NEW_FILES, ADD_NEW_FILES_CHILD } from '../types';
import { MAIN_FILES_API_URL, GET_FILES_API_URL } from '../API';
import { uploadedDocumentsEmail } from './emails';

export const removeNewFiles = () => ({
  type: REMOVE_NEW_FILES,
});

export const addNewFiles =
  (files, company, selectedStandard, selectedChildIndex, selectedChild, accreditationId) => async (dispatch) => {
    const user = await Auth.currentAuthenticatedUser();
    const email = user.attributes.email;

    function getCurrentDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so we add 1 and pad with '0'
      const day = String(today.getDate()).padStart(2, '0');
      const currentDate = `${year}-${month}-${day}`;
      return currentDate;
    }

    const filesNames = [];
    files.forEach((item) => {
      filesNames.push({
        name: item.name,
        type: item.type,
        uploaded_by: email,
        document_status: 'Under Review',
        upload_date: getCurrentDate(),
        company,
        standard_id: selectedStandard,
        standard_child_id: selectedChildIndex,
        selected_child_index: selectedChildIndex,
        accreditation_id: parseInt(accreditationId, 10),
      });
    });

    const firstFile = filesNames[0];

    dispatch({
      type: ADD_NEW_FILES,
      payload: filesNames,
    });

    dispatch({
      type: ADD_NEW_FILES_CHILD,
      payload: { standardId: firstFile.standard_id, standardChildId: firstFile.standard_child_id },
    });
  };

export const uploadFiles =
  (files, company, selectedStandard, selectedChildIndex, selectedChild, accreditationId, accreditationType) =>
  async (dispatch) => {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession.idToken.jwtToken;
    const email = user.attributes.email;

    const filesNames = [];
    files.forEach((item) => {
      filesNames.push({ name: item.name, type: item.type });
    });
    const postData = {
      email,
      company,
      selectedStandard,
      selectedChildIndex,
      selectedChild,
      files: filesNames,
      accreditationId,
      accreditationType,
    };

    try {
      axios
        .post(MAIN_FILES_API_URL, postData, {
          headers: {
            Authorization: `Bearer ${token}`,
            'content-type': 'application/json',
          },
        })
        .then((res) => {
          res.data.files.forEach(async (item, index) => {
            const result = await fetch(item, {
              method: 'PUT',
              headers: {
                'Content-Type': files[index].type,
              },
              body: files[index],
            });
          });
        });

      dispatch(uploadedDocumentsEmail(postData));

      dispatch(removeNewFiles());
    } catch (err) {
      if (err.response) {
        // The client was given an error response (5xx, 4xx)
        console.log(err.response.data);
        console.log(err.response.status);
        console.log(err.response.headers);
      } else if (err.request) {
        console.log(err.request);
        // The client never received a response, and the request was never left
      } else {
        console.log('Error', err.message);
      }
    }
  };

export const getDocumentData = (accreditationId, selectedStandard, selectedChild) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  dispatch({
    type: GETTING_FILES,
    payload: true,
  });
  try {
    const res = await axios.get(GET_FILES_API_URL, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        accreditationId,
        selectedStandard,
        selectedChild,
        email,
      },
    });
    if (res) {
      dispatch({
        type: GET_FILES,
        payload: res.data.files,
      });
      dispatch({
        type: GETTING_FILES,
        payload: false,
      });
    }
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};

export const getFile = (company, fileName, accreditationType, accreditationId) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  const type = 'GET';

  try {
    const res = await axios.get(MAIN_FILES_API_URL, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        fileName,
        email,
        company,
        type,
        accreditationType,
        accreditationId,
      },
    });
    if (res && res.data.url) {
      const link = document.createElement('a');
      link.href = res.data.url;
      link.target = '_blank'; // Open the link in a new tab or window
      link.download = fileName; // Use the provided fileName for download
      link.rel = 'noopener noreferrer'; // Indicate that the link should be treated as a download link
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};

export const deleteFile = (company, fileName, accreditationId, standardChildId, standardId) => async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  const token = user.signInUserSession.idToken.jwtToken;
  const email = user.attributes.email;
  const type = 'DELETE';

  try {
    const res = await axios.get(MAIN_FILES_API_URL, {
      headers: {
        'content-type': 'application/json',
        Authorization: 'Bearer '.concat(token),
      },
      params: {
        fileName,
        email,
        company,
        type,
        standardChildId,
        standardId,
        accreditationId,
      },
    });
    if (res && res.data) {
      try {
        // Make a DELETE request to the pre-signed URL
        await axios.delete(res.data.url);
        toast.success('File deleted successfully');
        dispatch({
          type: DELETE_FILE,
          payload: { standardChildId, standardId, accreditationId, fileName },
        });
      } catch (error) {
        toast.error('Error deleting the file. Please try again!');
      }
    }
  } catch (err) {
    if (err.response) {
      // The client was given an error response (5xx, 4xx)
      console.log(err.response.data);
      console.log(err.response.status);
      console.log(err.response.headers);
    } else if (err.request) {
      console.log(err.request);
      // The client never received a response, and the request was never left
    } else {
      console.log('Error', err.message);
    }
  }
};

import { Box, Card, CardContent, Container, Grid, Typography, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

export default function WhyAccredit() {
  const themeStatus = useSelector((state) => state.theme.themeStatus);
  const theme = useTheme();

  const WhyAccreditBullets = [
    {
      id: 1,
      description:
        'Embrace rigorous accreditation to demonstrate your commitment to excellence, setting your organization apart from competitors.',
    },
    {
      id: 2,
      description:
        'Showcase dedication to quality and continuous improvement, earning the coveted badge of excellence that instills confidence in your services.',
    },
    {
      id: 3,
      description:
        'Stand tall among competitors by building trust with patients, healthcare providers, and regulatory bodies through your accredited status, reinforcing your position as a leader in the industry.',
    },
  ];
  return (
    <Container>
      <Grid container spacing={4} className="flex justify-center align-center">
        <Grid item md={12} sx={{ mt: 2 }}>
          <Typography variant="h1">
            The Value of ACMA <span className="animate-charcter"> ACCREDITATION</span>
          </Typography>
          <Typography variant="h5" sx={{ mt: 3, color: themeStatus === 'dark' ? '#919eab' : 'black' }}>
            The Life Sciences Training & Education Standards set by ACMA mandate the adoption of a systematic approach
            to ongoing education and training. All standard categories must be managed within the organizational
            structure of the training provider, serving as an essential component for delivering training to
            professionals within the pharmaceutical industry.
          </Typography>
        </Grid>
        <Grid item md={6} sx={{ mt: 2 }}>
          <img
            src={
              'https://acma-media-bucket.s3.us-east-2.amazonaws.com/images/brand/accreditation_portal/msl-collage.png'
            }
            alt="MSLs"
          />
        </Grid>
        <Grid item md={6}>
          <Box>
            <Card
              sx={{
                borderBottomRightRadius: 'inherit',
                borderBottomLeftRadius: 'inherit',
                mt: 2,
                backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
                color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
              }}
            >
              <CardContent>
                <Typography>
                  Embrace rigorous accreditation to demonstrate your commitment to excellence, setting your organization
                  apart from competitors.
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                borderRadius: 'inherit',
                mt: 2,
                backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
                color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
              }}
            >
              <CardContent>
                <Typography>
                  Showcase dedication to quality and continuous improvement, earning the coveted badge of excellence
                  that instills confidence in your services.
                </Typography>
              </CardContent>
            </Card>

            <Card
              sx={{
                borderTopRightRadius: 'inherit',
                borderTopLeftRadius: 'inherit',
                mt: 2,
                backgroundColor: themeStatus === 'dark' ? theme.palette.darkbg.light : theme.palette.lightbg.light,
                color: themeStatus === 'dark' ? theme.palette.darkbg.color : theme.palette.lightbg.color,
              }}
            >
              <CardContent>
                <Typography>
                  Stand tall among competitors by building trust with patients, healthcare providers, and regulatory
                  bodies through your accredited status, reinforcing your position as a leader in the industry.
                </Typography>
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

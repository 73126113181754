export const GET_USER_DETAILS = 'GET_USER_DETAILS';

export const GET_STANDARDS = 'GET_STANDARDS';

export const UPLOAD_FILES = 'UPLOAD_FILES';
export const GET_FILES = 'GET_FILES';
export const GETTING_FILES = 'GETTING_FILES';
export const REMOVE_NEW_FILES = 'REMOVE_NEW_FILES';
export const ADD_NEW_FILES = 'ADD_NEW_FILES';
export const GET_DOCUMENT = 'GET_DOCUMENT';
export const DELETE_FILE = 'DELETE_FILE';

export const SET_THEME = 'SET_THEME';
export const GET_COMPANY_USERS = 'GET_COMPANY_USERS';
export const GET_ACCREDITATION = 'GET_ACCREDITATION';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const ADD_NEW_FILES_CHILD = 'ADD_NEW_FILES_CHILD';

export const CLEAR_STATE = 'CLEAR_STATE';

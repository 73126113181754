import { GET_ACCREDITATION, CLEAR_STATE } from '../types';

const initialState = {
  accreditationData: {},
  accreditationDataLoading: true,
};

export default function Caller(state = initialState, action) {
  switch (action.type) {
    case CLEAR_STATE:
      return {
        ...state,
        accreditationData: {},
        accreditationDataLoading: true,
      };
    case GET_ACCREDITATION:
      return {
        ...state,
        accreditationData: action.payload,
        accreditationDataLoading: false,
      };

    default:
      return state;
  }
}
